import {useRouter} from "next/router";
import {Dispatch, MutableRefObject, SetStateAction, useContext, useState} from "react";
import {ImageIcon} from "../atoms/icon";
import classNames from "classnames";
import {Dictionary, TranslatedUrls} from "../../types/hygiena-types";
import {DictionaryContext} from "../../context/dictionary-context";

interface Props {
  small?: boolean,
  closeRef?: MutableRefObject<Dispatch<SetStateAction<boolean|undefined>>>,
  entityInfo?: {
    id?: string,
    type?: string,
  },
  translatedUrls?: TranslatedUrls | null,
}

export function LanguageSelect (props: Props) {
  const router = useRouter();
  const locale = router.locale;
  const t = useContext<Dictionary>(DictionaryContext)
  const locales = router?.locales ?? ['en'];
  const translatedUrls = props?.translatedUrls;
  const [dropdown, setDropdown] = useState<boolean>();

  const activeLabel = t.languages?.[locale ?? 'en'];

  /**
   * Gets the translated page url by locale.
   *
   * @param locale
   */
  function getTranslatedUrl(locale: string): string {
    if (translatedUrls?.[locale]) return translatedUrls[locale];
    else if (locale === router?.defaultLocale) return '/';
    return `/${locale}`;
  }

  return (
    <>
      <div className="z-10">
        <div className="md:text-white pl-1 cursor-pointer relative md:flex md:items-center"
             onClick={() => setDropdown(!dropdown)}
             onKeyUp={(event) => {
               if (event.key === "Enter") setDropdown(!dropdown);
             }}
        >
          <div className="w-[25px] h-[25px] md:w-4 md:h-4 inline-block mr-2 pt-[4px] md:pt-0">
            <ImageIcon type="globe" color={!props?.small ? "white" : "black"} height={!props?.small ? 18 : 25}
                       width={!props?.small ? 18 : 25} alt={"Select a language"}/>
          </div>
          <span className="pr-4 md:min-w-[80px] inline-block max-md:font-bold max-md:text-lg"
                tabIndex={0}>{activeLabel?.length ? (
            <span className="animate-fade-in">{activeLabel}</span>) : <></>}</span>
          <ImageIcon type="chevron-down" color={!props?.small ? "white" : "black"}
                     className="inline-block align-middle ml-2 mb-1 md:mb-0" height={!props?.small ? 12 : 15}
                     width={!props?.small ? 12 : 15} alt={"Select a language"}/>
          <div className={classNames(
            "bg-white fixed md:absolute max-md:h-full max-md:left-0 max-md:w-full max-md:animate-fade-in md:top-full",
            {"hidden": !dropdown}
          )}>
            <ul onMouseLeave={() => setDropdown(false)}>
              {locales.map((val, i) => (
                <li
                  key={`language-select--${val}--${i}`}
                  className={classNames(
                    "text-black md:text-primary text-center font-bold max-md:border-none md:border-primary border-2 cursor-pointer border-b-0 first:border-t-0 last:border-b-2 rounded-[2px] leading-tight text-2xl md:text-sm bg-white hover:bg-primary hover:text-white transition-all duration-150",
                    {"text-primary": props?.small && (t.languages[val] === activeLabel)}
                  )}
                  tabIndex={0}
                  onClick={(event) => {setDropdown(false)}}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      setDropdown(false)
                    }
                  }}
                  aria-label={`${t.languages[val ?? 'en']}`} value={val}>
                  <a href={getTranslatedUrl(val)}
                     title={t.languages[val ?? 'en']}
                     className="h-full w-full block py-6 mt-6 md:mt-0 md:py-2 px-8 cursor-pointer">{t.languages[val ?? 'en']}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}