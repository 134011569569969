import {Link} from "./atoms/link";
import Image from 'next/image';
import Head from 'next/head';
import {deserialize, DrupalMenuLinkContent, DrupalNode, DrupalTaxonomyTerm} from 'next-drupal';
import NextNProgress from 'nextjs-progressbar';

import {PreviewAlert} from 'components/preview-alert';
import {HamburgerToggle, MenuMain} from 'components/templates/menu/menu--main';
import {MenuUtility} from "components/templates/menu/menu--utility";
import classNames from "classnames";
import {
  Dictionary,
  DrupalEmptyNavMenuLinkContent,
  DrupalMainMenuLinkContent,
  DrupalProductNode,
  DrupalWebform, TranslatedUrls
} from "../types/hygiena-types";
import dynamic from "next/dynamic";
import {ReactNode, MouseEvent, useEffect, useRef, useState, useContext} from "react";
import {WidgetSearch} from "./organisms/widget--search";
import {searchIndex} from "../lib/search-index";
import {useRouter} from "next/router";
import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import {LayoutTransition} from "./atoms/layout-transition";
import {ModalContext, ModalContextType} from "../context/modal-context";
import {SmallContext} from "../context/is-small-context";
import {WidgetModal, WidgetModalSmall} from "./organisms/widget--modal";
import {WidgetHeaderSearchResults} from "./organisms/widget-header-search-results";
import {EmptyNavMenuContext} from "../context/empty-nav-menu-context";
import {NodeAlertBanner} from "./templates/nodes/node--alert-banner";
import {DictionaryContext} from "../context/dictionary-context";
import {StoreContext, StoreContextType} from "@/context/store-context";

const ActonContactForm = dynamic(() => import('../components/templates/acton/acton--contact-form').then((mod) => mod.ActonContactForm));
const MenuFooter = dynamic(() => import('components/templates/menu/menu--footer').then((mod) => mod.MenuFooter));

export interface LayoutProps {
  title?: string
  children?: ReactNode
  metatag?: any,
  newsletterPad?: boolean,
  loadScripts?: boolean,
  customMenu?: DrupalMainMenuLinkContent[],
  hideUtilityMenu?: boolean,
  hideFooterMenu?: boolean,
  logoScrollToTop?: boolean,
  redirectTo?: string,
  alertBanner?: DrupalNode | null,
  contactFormTitle: string | null,
  contactFormDescription: string | null,
  entityInfo?: {
    id?: string,
    type?: string,
  },
  webforms?: {
    contactUs?: DrupalWebform | undefined | null
    industrySegments?: DrupalTaxonomyTerm[] | undefined | null
    contactUsTitle?: string | undefined | null
  },
  menus: {
    main: DrupalMenuLinkContent[]
    utility: DrupalMenuLinkContent[]
    footer: DrupalMenuLinkContent[]
    emptyNavMenu: DrupalEmptyNavMenuLinkContent[]
  },
  dictionary: Dictionary,
  translatedUrls: TranslatedUrls | null,
}

export function Layout({
  title,
  menus,
  children,
  webforms,
  metatag,
  entityInfo,
  contactFormTitle,
  contactFormDescription,
  newsletterPad,
  customMenu,
  redirectTo,
  alertBanner,
  loadScripts,
  ...props
}: LayoutProps) {
  const t = useContext<Dictionary>(DictionaryContext)
  const router = useRouter();
  const [searchResults, setSearchResults] = useState<DrupalProductNode[]>([]);
  const [searchResultsCount, setSearchResultsCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [stickyDelay, setStickyDelay] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [small, setSmall] = useState<boolean>(isSmall());
  const searchSubmitCallback = useRef<any>();
  const titleMeta = metatag?.filter(tag => tag?.attributes?.name === 'title')?.[0]?.attributes?.content ?? title;
  const [enableCart, setEnableCart] = useState<boolean>(false);

  // Small and large modal state variables.
  const [openModal, setOpenModal] = useState(false);
  const [openSmallModal, setOpenSmallModal] = useState(false);
  const [openModalContent, setOpenModalContent] = useState(<></>);
  const [openSmallModalContent, setOpenSmallModalContent] = useState(<></>);

  function modalOverlayClick(event: MouseEvent<HTMLDivElement>) {
    if (event?.target?.["classList"].contains("modal-overlay")) {
      setOpenModal(false);
      setOpenSmallModal(false);
    }
  }

  const modalContextValue: ModalContextType = {
    openModal,
    setOpenModal,
    openSmallModal,
    setOpenSmallModal,
    openModalContent,
    setOpenModalContent,
    openSmallModalContent,
    setOpenSmallModalContent,
    modalOverlayClick,
  }

  function isScrolling(event) {
    setIsSticky(window.scrollY > 0 || event.wheelDelta <= 0);
    if (typeof window !== "undefined") {
      window.setTimeout(() => {
        setStickyDelay(window.scrollY > 0 || event.wheelDelta <= 0);
      }, 25);
    }
  }

  function isSmall() {
    if (typeof window !== 'undefined') {
      return window.innerWidth < 768;
    }
    return false;
  }

  useEffect(() => {
    const resize = () => setSmall(isSmall());
    window.addEventListener('wheel', isScrolling);
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('wheel', isScrolling);
      window.removeEventListener('resize', resize);
    };
  });

  function updateSearchActive(isActive: boolean) {
    setSearchActive(isActive);
    if (!isActive) {
      setSearchResults([]);
    }
  }

  async function searchCallback(searchText) {
    setSearchLoading(true);
    if (searchText) {
      let results: DrupalProductNode[] = [];
      const params = new DrupalJsonApiParams()
        .addFilter("language_with_fallback", router?.locale ?? "en", "=")
        .addFilter("fulltext", searchText, "=")
        .addFilter("status", "1", "=")
        .addInclude(["field_product_image.image", "field_product_image.bf_image", "field_product_brand", "field_product_variations"])
        .addFields("node--product", ["field_product_image", "field_product_brand", "field_product_variations", "path", "body", "title"])
        .addPageLimit(5)
        .addPageOffset(0)
        .addSort("created", "DESC")
        .getQueryString();

      const productResponse = await searchIndex("products", params, router.locale, router.defaultLocale);
      const productCount = productResponse?.data?.meta?.count ?? 0;
      const products = await deserialize(productResponse?.data) as DrupalProductNode[];
      // const articles = await deserialize(articleResponse?.data) as DrupalNode[]
      if (Array.isArray(products)) {
        results.concat(products);
      }
      results.concat(Array.isArray(products) ? products : []);

      results = results.concat(Array.isArray(products) ? products : []);
      // results = results.concat(Array.isArray(articles) ? articles : []);
      results = results.slice(0, 5);

      setSearchResults(results);
      setSearchResultsCount(productCount);
    } else {
      setSearchResults([]);
      setSearchResultsCount(0);
    }
    setSearchLoading(false);
  }

  useEffect(() => {
    const now = new Date().getTime();
    const expirationTime = now + (24 * 60 * 60 * 1000);

    // Only run this client-side
    if (typeof window !== 'undefined') {
      let showCartStorage: string | null = localStorage.getItem("showCart");

      if (showCartStorage) {
        try {
          const [showCart, expireTime] = showCartStorage.split(':');
          const expire = parseInt(expireTime);

          // If not expired, use the stored value
          if (now < expire) {
            setEnableCart(showCart === '1');
            return;
          }
        } catch (error) {
          // If parsing fails, continue to fetch
          console.error("Error parsing showCart storage", error);
        }
      }

      // If no valid localStorage or expired, fetch from API
      fetch('/api/show-cart')
        .then((response) => response.json())
        .then((data) => {
          if (data?.ok) {
            localStorage.setItem('showCart', `1:${expirationTime}`);
            setEnableCart(true);
          } else {
            localStorage.setItem('showCart', `0:${expirationTime}`);
            setEnableCart(false);
          }
        })
        .catch((error) => {
          localStorage.setItem('showCart', `0:${expirationTime}`);
          setEnableCart(false);
        });
    }
  }, []);

  let contactUsFormTitle: string = '';
  let contactUsFormDescription: string = '';
  if (contactFormTitle?.length) {
    contactUsFormTitle = contactFormTitle;
  }
  else {
    contactUsFormTitle = webforms?.contactUs?.title?.length ? webforms?.contactUs?.title : t?.all?.schedule_meeting;
  }
  if (contactFormDescription?.length) {
    contactUsFormDescription = contactFormDescription;
  }
  else {
    contactUsFormDescription = t?.all?.schedule_meeting_desc;
  }

  return (
    <>
      <Head>
        <title>{`${titleMeta} | Hygiena`}</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon-192.png"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-192.png"/>
        {props?.translatedUrls && Object.keys(props.translatedUrls).map(key => {
          if (router.locales?.includes(key)) {
            return (
              <link key={`hreflang--${key}`} rel="alternate" hrefLang={key}
                    href={`${process?.env?.NEXT_PUBLIC_SITE_URL}${props?.translatedUrls?.[key]}` ?? `/${key}`}/>
            )
          }
        })}
        {/* @todo clean up the slick inclusion. */}
        <link rel="preload" href="/logo.svg" as={"image"}/>
        {metatag?.length ? metatag.map((item, idx) => {
          if (item?.tag === 'meta') {
            if (item?.attributes?.property?.includes(["og:url"])) {
              if (process.env.LOCAL_PROD_BUILD && process.env.NEXT_PUBLIC_ACQUIA_BASE_URL) {
                item.attributes.content = item.attributes.content.replace(process?.env?.NEXT_PUBLIC_ACQUIA_BASE_URL, process?.env?.NEXT_PUBLIC_SITE_URL);
              } else {
                item.attributes.content = item.attributes.content.replace(process?.env?.NEXT_PUBLIC_DRUPAL_BASE_URL, process?.env?.NEXT_PUBLIC_SITE_URL);
              }
            }
            return <meta
              key={item + "-meta-" + idx}
              {...item.attributes}/>
          }
          if (item?.tag === 'link') {
            if (item?.attributes?.rel === "canonical" && item?.attributes?.href) {
              if (process.env.LOCAL_PROD_BUILD && process.env.NEXT_PUBLIC_ACQUIA_BASE_URL) {
                item.attributes.href = item.attributes.href.replace(process?.env?.NEXT_PUBLIC_ACQUIA_BASE_URL, process?.env?.NEXT_PUBLIC_SITE_URL);
              } else {
                item.attributes.href = item.attributes.href.replace(process?.env?.NEXT_PUBLIC_DRUPAL_BASE_URL, process?.env?.NEXT_PUBLIC_SITE_URL);
              }
            }
            return <link
              key={item + "-meta-" + idx}
              {...item.attributes}/>
          }
        }) : <></>}
      </Head>
      {alertBanner && <NodeAlertBanner node={alertBanner}/>}
      <PreviewAlert/>
      <div className={classNames("flex flex-col min-h-screen")}>
        <header className={classNames(
          "border-b sticky top-0 bg-white z-[9999] px-6 lg:px-0 transition-top duration-300",
          {"md:-top-[40px]": isSticky},
        )}>
          <div className={classNames(
            "bg-primary color-black bottom-0 transition-all duration-300 relative max-h-[40px]",
          )}>
            <div className={classNames("container mx-auto lg:flex justify-end hidden px-4 xl:px-0")}>
              {menus?.utility &&
                <MenuUtility key={`${router.locale}--utility-menu`} hide={props?.hideUtilityMenu} menu={menus.utility}
                             entityInfo={entityInfo} translatedUrls={props?.translatedUrls}/>}
            </div>
          </div>
          <div className={classNames(
            "container relative flex items-center lg:justify-between py-4 mx-auto flex-row transition-all duration-75 px-4 xl:px-0",
          )}>
            <Link
              href={router.locale === router.defaultLocale ? '/' : `/${router.locale}`}
              onClick={(event) => {
                if (typeof window !== "undefined" && props?.logoScrollToTop) {
                  event.preventDefault();
                  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                }
              }}
              className={classNames(
                "header-logo flex items-center space-x-2 no-underline flex-grow lg:flex-grow-0 mb-0",
              )}
            >
              <div className={classNames("w-[140px] md:w-44 h-12 flex")}>
                <Image className="duration-200" src="/logo.svg" sizes="(min-width: 1024px) 180px, 140" priority
                       alt="Logo" width={isSticky ? 140 : 180} height={48}/>
              </div>
            </Link>
            {menus?.main && (
              <EmptyNavMenuContext.Provider value={menus?.emptyNavMenu ?? []}>
                <MenuMain key={`${router.locale}--main-menu`} menu={customMenu?.length ? customMenu : menus.main}
                          menuOpen={menuOpen} setMenuOpen={setMenuOpen} translatedUrls={props?.translatedUrls}/>
              </EmptyNavMenuContext.Provider>
            )}
            {!customMenu?.length ? (
              <StoreContext.Provider value={{enableCart, setEnableCart}}>
                <WidgetSearch callback={searchCallback} active={searchActive} setActive={updateSearchActive}
                              loading={searchLoading} searchSubmitRef={searchSubmitCallback}/>
              </StoreContext.Provider>
            ) : <></>}
            <HamburgerToggle menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
            <WidgetHeaderSearchResults searchResults={searchResults} searchActive={searchActive} isSticky={isSticky}
                                       submitCallback={searchSubmitCallback?.current}
                                       searchResultsCount={searchResultsCount} itemCallback={() => {
              setSearchResults([]);
              setSearchActive(false);
            }}
            />
          </div>
        </header>
        <NextNProgress color={!isSticky ? "#fff" : "#00AEEF"}/>
        <SmallContext.Provider value={small}>
          <StoreContext.Provider value={{enableCart, setEnableCart}}>
            <ModalContext.Provider value={modalContextValue}>
              <LayoutTransition>
                {children}
                {loadScripts && webforms?.contactUs ? (
                  <>
                    <ActonContactForm sectionTitle={contactUsFormTitle} sectionDescription={contactUsFormDescription}/>
                  </>
                ) : newsletterPad ? <div className="mt-12"/> : <></>}
              </LayoutTransition>
            </ModalContext.Provider>
          </StoreContext.Provider>
        </SmallContext.Provider>
        <WidgetModal openModal={openModal} setOpenModalContent={setOpenModalContent} setOpenModal={setOpenModal}
                     modalOverlayClick={modalOverlayClick} openModalContent={openModalContent}/>
        <WidgetModalSmall openSmallModal={openSmallModal} openSmallModalContent={openSmallModalContent}
                          setOpenSmallModal={setOpenSmallModal} modalOverlayClick={modalOverlayClick}
                          setOpenSmallModalContent={setOpenSmallModalContent}/>
        <footer>
          {!props?.hideFooterMenu && (
            <>
              <div className="container flex mx-auto flex-wrap">
                <div className="pt-8 pb-12 md:py-16 mx-auto md:mx-0 w-full">
                  {menus?.footer && <MenuFooter key={`${router.locale}--footer-menu`} menu={menus.footer}/>}
                </div>
              </div>
              <div className="bg-gray-50">
                <div className="container mx-auto text-xs">
                  <div className="text-xs py-3 text-center text-gray-600">
                    ⓒ {new Date().getFullYear()} {`${t?.layout?.copyright}`} Hygiena LLC
                  </div>
                </div>
              </div>
            </>
          )}
        </footer>
      </div>
    </>
  );
}
