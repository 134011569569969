import {LinkProps} from "next/link";
import {Link} from "./link";
import {HTMLProps, LinkHTMLAttributes, MouseEvent, MouseEventHandler, MutableRefObject, useState} from "react";
import {HygienaColorOption} from "../../types/hygiena-types";
import classNames from "classnames";
import {IconType, ImageIcon} from "./icon";
import {useRouter} from "next/router";

interface Props extends Partial<LinkProps>, HygienaColorOption {
  callback?: MouseEventHandler<HTMLButtonElement|HTMLAnchorElement>,
  containerClassName?: string,
  href?: string|object,
  className?: string,
  invert?: boolean,
  invertColor?: string,
  icon?: string,
  iconColor?: string,
  position?: "left"|"right"|"both",
  children?: any,
  target?: string,
  iconClass?: string,
  hover?: HygienaColorOption,
  responsiveIcon?: boolean,
  rotateIcon?: boolean,
  viewBox?: string,
  title?: string,
  buttonRef?: MutableRefObject<any>,
  height?: number,
  width?: number,
  rel?: string,
}

/**
 * Renders a hygiena style button or link button.
 *
 * @param callback
 * @param invert
 * @param props
 * @constructor
 */
export function Button({callback, invert, invertColor, iconClass, responsiveIcon, title, height = 12, width = 12, ...props}: Props): JSX.Element {
  const color = props.color ?? "primary";
  const hover = props?.hover?.color;
  const position = props.position ?? "left";
  // const [isInverted, setIsInverted] = useState<boolean>(invert);
  const [hovering, setHovering] = useState<boolean>()
  const iconColor = props.iconColor ?? color;
  const router = useRouter();
  const linkProps: HTMLProps<HTMLLinkElement> = {}
  if (props.rel) {
    linkProps.rel = props.rel;
  }


  function isInverted(): boolean {
    if (hovering && invert) return false;
    if (!hovering && invert) return true;
    if (!hovering && !invert) return false;
    if (hovering && !invert) return true;
    return false;
  }

  function onMouseEnter() {
    setHovering(true);
  }
  function onMouseLeave() {
    setHovering(false);
  }

  function renderIcon(left?: boolean) {
    return (
      <div className={classNames("relative", {"mr-2": left, "ml-2": !left, "max-md:hidden": responsiveIcon}, iconClass)} style={{width: `${width}px`, height: `${height}px`}}>
        <ImageIcon type={props.icon as IconType} color={invertColor ?? "white"} viewBox={props.viewBox} className={classNames(
          iconClass ?? "inline",
          "rotate-0 transition-all duration-200 absolute left-0",
          {"opacity-0": isInverted()},
          {"!-rotate-180": props.rotateIcon},
          {"max-md:hidden": responsiveIcon}
        )} alt={props.icon ?? ""} height={height} width={width} />
        <ImageIcon type={props.icon as IconType} color={iconColor} viewBox={props.viewBox} className={classNames(
          iconClass ?? "inline",
          "rotate-0 transition-all duration-200 absolute left-0",
          {"opacity-0": !isInverted()},
          {"!-rotate-180": props.rotateIcon},
          {"max-md:hidden": responsiveIcon}
        )} alt={props.icon ?? ""} height={height} width={width} />
      </div>
    )
  }

  function linkCallback(event: MouseEvent<HTMLAnchorElement >) {
    if (callback) {
      callback(event);
    }
    else {
      const target = event.target as HTMLAnchorElement;
      const isAnchor = (props?.href && typeof props.href === 'string') ? props.href.startsWith("#") : false;
      if (isAnchor && typeof props.href === 'string') {
        const element = document.getElementById(props.href.replace('#', ''));
        if (element) {
          event.preventDefault();
          element.scrollIntoView({behavior: 'smooth'})
        }
      }
    }
  }

  if (!props.href && callback) {
    return (
      <div className={classNames("inline-block cursor-pointer", props.containerClassName)} ref={props.buttonRef ?? undefined} >
        <button role="button" aria-label={title ?? undefined} onClick={callback} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={classNames(
          "py-2 px-3 border-2 font-bold rounded-sm flex flex-row items-center justify-center transition-all duration-500",
          // Not Inverted
          {"border-primary bg-primary text-white": color === "primary" && !invert},
          {"border-accent bg-accent-red text-white": color === "accent" && !invert},
          {"border-complimentary bg-complimentary text-white": color === "complimentary" && !invert},
          {"border-tertiary bg-tertiary text-white": color === "tertiary" && !invert},
          // Inverted
          {"border-2 border-primary text-primary": color === "primary" && invert},
          {"border-2 border-accent-red text-accent": color === "accent" && invert},
          {"border-2 border-complimentary text-complimentary": color === "complimentary" && invert},
          {"border-2 border-tertiary text-tertiary": color === "tertiary" && invert},
          {"border-2 border-grey-400 text-black": color === "black"},
          // Hover Inverted.
          {"hover:border-primary hover:bg-primary hover:text-white": hover === "primary" && invert},
          {"hover:border-accent-red hover:bg-accent-red hover:text-white": hover === "accent" && invert},
          {"hover:border-complimentary hover:bg-complimentary hover:text-white": hover === "complimentary" && invert},
          {"hover:border-tertiary hover:bg-tertiary hover:text-white": hover === "tertiary" && invert},
          // Hover Not Inverted
          {"hover:border-2 hover:border-primary hover:text-primary hover:bg-white": hover === "primary" && !invert},
          {"hover:border-2 hover:border-accent-red hover:text-accent-red hover:bg-white": hover === "accent" && !invert},
          {"hover:border-2 hover:border-complimentary hover:text-complimentary hover:bg-white": hover === "complimentary" && !invert},
          {"hover:border-2 hover:border-tertiary hover:text-tertiary hover:bg-white": hover === "tertiary" && !invert},
          {"hover:border-2 hover:border-black hover:text-black hover:bg-white": hover === "black"},
          props.className,
        )}>
          {props.icon && ["left", "both"].includes(position) && (
            <>{renderIcon(true)}</>
          )}
          {props.children}
          {props.icon && ["right", "both"].includes(position) && (
            <>{renderIcon(false)}</>
          )}
        </button>
      </div>
    )
  }
  else if (props.href) {
    return (
      <div className={classNames("inline-block cursor-pointer", props.containerClassName)}>
        <Link href={props.href ?? "/"} target={props.target} onClick={linkCallback} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={classNames(
          "py-2 px-3 border-2 font-bold rounded-sm flex flex-row items-center justify-center transition-all duration-500",
          // Not Inverted
          {"border-primary bg-primary text-white": color === "primary" && !invert},
          {"border-accent-red bg-accent-red text-white": color === "accent" && !invert},
          {"border-complimentary bg-complimentary text-white": color === "complimentary" && !invert},
          {"border-tertiary bg-tertiary text-white": color === "tertiary" && !invert},
          // Inverted
          {"border-2 border-primary text-primary": color === "primary" && invert},
          {"border-2 border-accent-red text-accent": color === "accent" && invert},
          {"border-2 border-complimentary text-complimentary": color === "complimentary" && invert},
          {"border-2 border-tertiary text-tertiary": color === "tertiary" && invert},
          {"border-2 border-grey-400 text-black": color === "black"},
          // Hover Inverted.
          {"hover:border-primary hover:bg-primary hover:text-white": hover === "primary" && invert},
          {"hover:border-accent-red hover:bg-accent-red hover:text-white": hover === "accent" && invert},
          {"hover:border-complimentary hover:bg-complimentary hover:text-white": hover === "complimentary" && invert},
          {"hover:border-tertiary hover:bg-tertiary hover:text-white": hover === "tertiary" && invert},
          // Hover Not Inverted
          {"hover:border-2 hover:border-primary hover:text-primary hover:bg-white": hover === "primary" && !invert},
          {"hover:border-2 hover:border-accent-red hover:text-accent-red hover:bg-white": hover === "accent" && !invert},
          {"hover:border-2 hover:border-complimentary hover:text-complimentary hover:bg-white": hover === "complimentary" && !invert},
          {"hover:border-2 hover:border-tertiary hover:text-tertiary hover:bg-white": hover === "tertiary" && !invert},
          {"hover:border-2 hover:border-black hover:text-black hover:bg-white": hover === "black"},
          props.className,
        )} {...linkProps}>
          {props.icon && ["left", "both"].includes(position) && (
            <>{renderIcon(true)}</>
          )}
          {props.children}
          {props.icon && ["right", "both"].includes(position) && (
            <>{renderIcon(false)}</>
          )}
        </Link>
      </div>
    )
  }

  return <></>
}

export function LinkButton() {

}